<template>
  <v-row class="bg my-0">
    <v-col md="12">
      <v-row justify="center" align="center">
        <v-col md="12">
          <h2 :class="fontSize()" class="font-weight-bold text-center py-5">
            Datos de Interés
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="pb-10">
        <v-col cols="12" md="3">
          <v-img
            :src="imagen1"
            contain
            :height="$vuetify.breakpoint.lgAndDown ? '125' : '225'"
          ></v-img>
        </v-col>
        <v-divider class="white hidden-sm-and-down" vertical />
        <v-col cols="12" md="3"
          ><v-img
            :src="imagen2"
            :height="$vuetify.breakpoint.lgAndDown ? '125' : '225'"
            contain
        /></v-col>
        <v-divider class="white hidden-sm-and-down" vertical />
        <v-col cols="12" md="3"
          ><v-img
            :src="imagen3"
            :height="$vuetify.breakpoint.lgAndDown ? '125' : '225'"
            contain
        /></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BloqueTres",
  data: () => ({
    imagen1: require("@/assets/images/desk/opciones_ut.png"),
    imagen2: require("@/assets/images/desk/laboratorios_ut.png"),
    imagen3: require("@/assets/images/desk/egresaados_ut.png"),
  }),
  methods: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-center headline";
        case "sm":
          return "text-center display-2";
        case "md":
          return "text-left display-1";
        case "lg":
          return "text-left display-2";
        case "xl":
          return "text-left display-3";
      }
    },
  },
};
</script>
<style scoped>
.bg {
  background-color: #018e25;
}
h2 {
  color: white;
}
</style>
