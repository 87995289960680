<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" :order="$vuetify.breakpoint.mdAndUp ? 1 : 2">
      <div :id="$vuetify.breakpoint.mdAndUp ? 'container' : 'containerMobile'">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/qXtNOjjkhAo"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </v-col>
    <v-col cols="12" md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1">
      <CBloque02 />
    </v-col>
  </v-row>
</template>
<script>
import CBloque02 from "@/components/content/ContenidoBloque02";
export default {
  name: "BloqueDos",
  components: {
    CBloque02,
  },
};
</script>
<style>
:root {
  --height: 45vw;
  --heightMobile: 97vw;
}
#container {
  display: block;
  width: var(--height);
  max-width: 177.78vh;
  /* 16/9 = 1.778 */
  height: calc((var(--height) * 9) / 16);
  /* height:width ratio = 9/16 = .5625  */
  max-height: 80vw;
}
#containerMobile {
  display: block;
  width: var(--heightMobile);
  max-width: 177.78vh;
  /* 16/9 = 1.778 */
  height: calc((var(--heightMobile) * 9) / 16);
  /* height:width ratio = 9/16 = .5625  */
  max-height: 80vw;
}
</style>
