<template>
  <v-row
    :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'"
    align="center"
  >
    <v-col cols="12">
      <v-row
        :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'"
        class="py-10"
      >
        <v-col cols="12" md="12">
          <h2 :class="fontSizeTitle()" class="font-weight-bold">
            ¿Por qué estudiar en la UTSLP?
          </h2>
        </v-col>
        <v-col cols="12" v-for="(item, index) in contenido" :key="index">
          <v-row align="center">
            <v-col
              cols="3"
              md="2"
              :class="$vuetify.breakpoint.smAndDown ? 'pl-8' : ''"
            >
              <v-img :src="item.img" contain></v-img>
            </v-col>
            <v-col md="9" class="pb-0">
              <p class="text-left mb-0 font-weight-bold" :class="fontSize()">
                {{ item.titulo }}
              </p>
              <p
                class="text-left subtitulo mb-0 font-weight-bold font-italic"
                :class="fontSizeSub()"
              >
                {{ item.subtitulo }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" offset-md="2" md="9">
              <p class="text-left mb-0" :class="fontSizeText()">
                {{ item.texto }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "CBloque02",
  data: function () {
    return {
      contenido: [
        {
          img: require("@/assets/images/desk/icono_libro_ut.png"),
          titulo: "Modelo 30% teórico",
          subtitulo: "70% práctico",
          texto: `Sabemos que disfrutas y aprendes más con la práctica qué
                sólo con la teoría, por eso nos enfocamos más en ello`,
        },
        {
          img: require("@/assets/images/desk/icono_certificado_ut.png"),
          titulo: "Obtienes 2 Títulos",
          subtitulo: "Profesionales",
          texto: `Obtén tu título como Técnico Superior Universitario
                en 2 años y continúa con nosotros hasta obtener tu ingeniería o
                licenciatura`,
        },
      ],
    };
  },
  methods: {
    fontSizeTitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-2";
        case "md":
          return "display-1";
        case "lg":
          return "display-2";
        case "xl":
          return "display-2";
      }
    },
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-1";
        case "md":
          return "title";
        case "lg":
          return "display-1";
        case "xl":
          return "display-2";
      }
    },
    fontSizeSub() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-1";
        case "md":
          return "display-1";
        case "lg":
          return "display-2";
        case "xl":
          return "display-2";
      }
    },
    fontSizeText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "pl-8";
        case "md":
          return "headline ";
        case "lg":
          return "title";
        case "xl":
          return "display-1";
      }
    },
  },
};
</script>
<style scoped>
h2 {
  color: #4c4c4c;
}
.subtitulo {
  color: #018e25;
}
</style>
