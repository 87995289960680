<template>
  <v-navigation-drawer
    v-model="$store.state.drawer"
    absolute
    color="#018E25"
    style="position: fixed; z-index: 999"
    right
  >
    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="click(item.goTo)"
      >
        <v-list-item-icon>
          <v-icon color="#ffffff">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "DrawerM",
  data() {
    return {
      items: [
        { title: "Inicio", icon: "mdi-home", goTo: "#Bloque01" },
        {
          title: "¿Por qué la UT?",
          icon: "mdi-account-question",
          goTo: "#Bloque02",
        },
        {
          title: "Datos de interés",
          icon: "mdi-account-alert",
          goTo: "#Bloque03",
        },
        { title: "Carreras", icon: "mdi-school", goTo: "#Bloque04" },
        { title: "Testimonios", icon: "mdi-message", goTo: "#Bloque05" },
        {
          title: "Preguntas frecuentes",
          icon: "mdi-help-box",
          goTo: "#Bloque06",
        },
        {
          title: "Descarga tu plan",
          icon: "mdi-arrow-down-circle",
          goTo: "#Bloque07",
        },
      ],
    };
  },
  watch: {
    "$store.state.drawer": function () {},
  },
  methods: {
    click: function (goTo) {
      this.$vuetify.goTo(goTo, { duration: 1000 });
      this.$store.commit("updateDrawer");
    },
  },
};
</script>
<style scoped lang="scss">
.v-list-item__title {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
  text-align: left !important;
  padding-left: 2rem;
  color: #ffffff;
}
</style>
