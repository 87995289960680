<template>
  <v-main fluid class="pa-0">
    <v-row align="center" justify="center" class="pb-10">
      <v-col
        cols="10"
        sm="12"
        md="6"
        :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
      >
        <v-row align="start" justify="center">
          <v-col cols="4" sm="2">
            <v-img :src="logoUTSLP" contain />
          </v-col>
          <v-col cols="12" sm="8">
            <p class="text-left" :class="fontSizeText()">
              <b>Universidad Tecnológica de San Luis Potosí</b>
            </p>
            <p class="text-left" :class="fontSizeText()">
              Realiza tu trámite de la ficha vía WhatsApp
              <a
                href="https://api.whatsapp.com/send?l=es&phone=5214446653461"
                target="_blank"
                >(444) 665 3461
              </a>
              <br />
              <br />
              Av. Dr. Arturo Nava Jaimes No. 100. Rancho Nuevo Soledad de G.S.
              San Luis Potosí
            </p>
            <div class="text-left">
              <v-btn
                href="https://www.google.com/maps/dir/21.9086848,-99.401728/utslp/@22.2213945,-101.2844217,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x842aa1955610cd69:0xad63c85c0cedf28d!2m2!1d-100.9291488!2d22.2198054"
                style="background-color: #018e25; color: #ffffff !important"
              >
                Ver Ubicación
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" offset-sm="3">
            <p class="text-left font-weight-bold" :class="fontSizeText()">
              Mapa de sitio
            </p>
            <ul class="text-left pl-0" :class="fontSizeText()">
              <li>
                <a @click="$vuetify.goTo('#Bloque01', { duration: 1000 })"
                  >&bull; Inicio</a
                >
              </li>
              <li>
                <a @click="$vuetify.goTo('#Bloque02', { duration: 1000 })"
                  >&bull; ¿Por qué la UT?</a
                >
              </li>
              <li>
                <a @click="$vuetify.goTo('#Bloque03', { duration: 1000 })"
                  >&bull; Datos De Interés</a
                >
              </li>
              <li>
                <a @click="$vuetify.goTo('#Bloque04', { duration: 1000 })"
                  >&bull; Carreras</a
                >
              </li>
              <li>
                <a @click="$vuetify.goTo('#Bloque05', { duration: 1000 })"
                  >&bull; Testimonios</a
                >
              </li>
              <li>
                <a @click="$vuetify.goTo('#Bloque06', { duration: 1000 })"
                  >&bull; Preguntas Frecuentes</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" :order="$vuetify.breakpoint.smAndDown ? 1 : 2">
        <h2 :class="fontSize()" class="font-weight-bold py-5">
          Descarga tu plan de estudios
        </h2>
        <Formulario></Formulario>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="bg py-0">
      <v-col cols="12" md="3">
        <p :class="fontSizeFoot()">
          Copyright &#169; {{ new Date().getFullYear() }} UTSLP. Todos los
          derechos reservados.
        </p>
      </v-col>
      <v-col cols="6" md="3" :offset="$vuetify.breakpoint.smAndDown ? 3 : 1">
        <a href="https://conviertes.com/"
          ><v-img :src="logoConviertes" contain width="50%"
        /></a>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import Formulario from "./Formulario";
export default {
  name: "BloqueSiete",
  components: {
    Formulario,
  },
  data: () => ({
    tel: "tel: 4448348300",
    telText: "444 665 3461",
    logoUTSLP: require("@/assets/images/desk/logo_banner.png"),
    logoConviertes: require("@/assets/images/desk/conviertes-logo.png"),
  }),
  methods: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline pb-6";
        case "sm":
          return "display-2 pb-6";
        case "md":
          return "display-1 pb-12";
        case "lg":
          return "display-2 pb-12";
        case "xl":
          return "display-3 pb-12";
      }
    },
    fontSizeText() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "headline";
        case "lg":
          return "title";
        case "xl":
          return "display-1";
      }
    },
    fontSizeFoot() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "headline";
        case "lg":
          return "title";
        case "xl":
          return "title";
      }
    },
  },
};
</script>
<style scoped>
li {
  list-style: none;
}
a {
  color: #666666 !important;
}
.bg {
  background-color: #ededed;
}
</style>
