<template>
  <v-row class="bg pb-10" align="start" justify="center">
    <v-col md="12" class="pb-0">
      <h2 class="font-weight-bold white--text" :class="fontSize()">
        Preguntas Frecuentes
      </h2>
      <v-row align="start" justify="center">
        <v-col md="5" class="pb-0">
          <v-row align="start">
            <v-col
              cols="12"
              md="11"
              v-for="(item, index) in preguntas"
              :key="'izquierda' + index"
              class="pb-0"
            >
              <v-row>
                <v-col cols="2" md="2">
                  <v-icon color="#E98718" class="text-left"
                    >mdi-check-circle</v-icon
                  >
                </v-col>
                <v-col cols="10" md="10">
                  <div
                    class="white--text pregunta text-left"
                    :class="fontSizeText()"
                  >
                    <b>{{ item.pregunta }}</b>
                  </div>
                  <div
                    class="white--text text-left mb-0 pt-3"
                    :class="fontSizeText()"
                  >
                    {{ item.respuesta }}<br />
                    <br />
                    <span v-if="item.paso1">{{ item.paso1 }}</span
                    ><br />
                    <a
                      class="white--text"
                      target="_blank"
                      :href="item.linkLateral"
                      v-if="item.linkLateral"
                      >{{ item.linkText }}</a
                    >
                  </div>
                  <div
                    v-if="item.paso2"
                    class="white--text text-left mb-0 pt-3"
                    :class="fontSizeText()"
                  >
                    <span>{{ item.paso2 }}</span>
                  </div>
                  <div
                    v-if="item.link"
                    class="text-left mb-0"
                    :class="fontSizeText()"
                  >
                    <a class="white--text" :href="item.link" target="_blank">
                      <b>{{ item.linkText }}</b>
                    </a>
                  </div>
                  <div
                    v-if="item.datosBancarios"
                    class="white--text text-left mb-0"
                    :class="fontSizeText()"
                  >
                    <ul>
                      <li>Cantidad: {{ item.datosBancarios.Cantidad1 }}</li>
                    </ul>
                    <b>Opcion 1:</b> Banco
                    <ul>
                      <li><b>Banco:</b> {{ item.datosBancarios.Banco }}</li>
                      <li><b>Cuenta:</b> {{ item.datosBancarios.Cuenta }}</li>
                      <li>
                        <b>Servicio:</b> {{ item.datosBancarios.Servicio }}
                      </li>
                      <li>
                        <b>Cantidad:</b> {{ item.datosBancarios.Cantidad1 }}
                      </li>
                      <li><b>Ref1:</b> {{ item.datosBancarios.Ref1 }}</li>
                      <li><b>Ref2:</b> {{ item.datosBancarios.Ref2 }}</li>
                    </ul>
                    <b>Opcion 2:</b> Transferencia
                    <ul>
                      <li><b>Banco:</b> {{ item.datosBancarios.Banco }}</li>
                      <li><b>CLABE:</b> {{ item.datosBancarios.CLABE }}</li>
                      <li><b>Ref1:</b> {{ item.datosBancarios.Ref1 }}</li>
                      <li><b>Ref2:</b> {{ item.datosBancarios.Ref2 }}</li>
                    </ul>
                    <b>Opcion 3:</b> Página UTSLP
                    <a
                      class="white--text"
                      :href="item.linkLateral2"
                      target="_blank"
                      >{{ item.linkLateral2 }}</a
                    >
                  </div>
                  <div
                    v-if="item.paso3"
                    class="white--text text-left mb-0 pt-3"
                    :class="fontSizeText()"
                  >
                    <span>{{ item.paso3 }}</span>
                    <a
                      class="white--text"
                      :href="item.comprobante"
                      target="_blank"
                    >
                      {{ item.comprobante }}</a
                    >
                  </div>
                  <div
                    v-if="item.paso4"
                    class="white--text text-left mb-0 pt-3"
                    :class="fontSizeText()"
                  >
                    <span>{{ item.paso4 }} </span>
                    <a
                      class="white--text"
                      href="https://api.whatsapp.com/send?l=es&phone=5214446653461"
                      target="_blank"
                      >(444) 665 3461</a
                    >
                    o
                    <a
                      class="white--text"
                      href="https://api.whatsapp.com/send?l=es&phone=5214445790633"
                      target="_blank"
                      >(444) 579 0633</a
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-divider class="white hidden-sm-and-down" vertical />
        <v-col cols="12" md="5">
          <v-row>
            <v-col
              cols="12"
              md="11"
              v-for="(item, index) in preguntasDerecha"
              :key="index"
              class="pb-0"
            >
              <v-row>
                <v-col cols="2" md="2">
                  <v-icon color="#E98718" class="text-left"
                    >mdi-check-circle</v-icon
                  >
                </v-col>
                <v-col cols="10" md="10">
                  <div
                    class="white--text pregunta text-left"
                    :class="fontSizeText()"
                  >
                    <b>{{ item.pregunta }}</b>
                  </div>
                  <div
                    class="white--text text-left mb-0"
                    :class="fontSizeText()"
                  >
                    {{ item.respuesta }}
                    <span v-if="item.complemento">{{ item.complemento }}</span>
                  </div>
                  <div
                    class="white--text text-left mb-0"
                    v-if="item.respuesta01"
                    :class="fontSizeText()"
                  >
                    {{ item.respuesta01 }} <b>{{ item.bold01 }}</b>
                  </div>
                  <div
                    class="white--text text-left mb-0"
                    v-if="item.respuesta02"
                    :class="fontSizeText()"
                  >
                    {{ item.respuesta02 }} <b>{{ item.bold02 }}</b>
                  </div>
                  <div
                    class="white--text text-left mb-0"
                    v-if="item.parrafo01"
                    :class="fontSizeText()"
                  >
                    {{ item.parrafo01 }}
                    <b
                      ><i>{{ item.bold }}</i></b
                    >
                    {{ item.parrafo02 }} <b>{{ item.marcas }}</b>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BloqueSeis",
  data: function () {
    return {
      preguntas: [
        {
          pregunta: "¿Cuándo se presenta el examen de admisión?",
          respuesta: "5 de Agosto de 2023.",
        },
        {
          pregunta:
            "¿Cuáles son los requisitos para tramitar la ficha de preinscripción?",
          respuesta: "Realiza los siguientes pasos:  ",
          paso1: "1.- Regístrate en este enlace",
          paso2: "2.- Realizar el pago:",
          paso3: "3.- Sube tu comprobante de pago:",
          paso4: "4.- Notifica a cualquiera de los siguientes WhatsApp:",
          linkText: "Ir al Enlace",
          linkLateral:
            "http://sito.utslp.edu.mx/jsp/escolar/proceso_admision/proceso_interesado.jsp?xModalidadP=N",
          linkLateral2: "http://www.utslp.edu.mx/banco/",
          comprobante: "https://forms.gle/BvqSsUkrFXfCVF6o6",
          datosBancarios: {
            Cantidad1: "$650 MXN",
            Banco: "Banbajio",
            Cuenta: "0164888680201",
            CLABE: "030700900008360345",
            Servicio: "1551",
            Ref1: "Ficha de admisión",
            Ref2: "Nombre completo comenzando con el apellido paterno",
            Pago: "$520 MXN",
          },
        },
        {
          pregunta: "¿Cuándo inician las clases?",
          respuesta: "22 de agosto (Primer Cuatrimestre)",
        },
        {
          pregunta: "¿Cómo se estudia esta modalidad?",
          respuesta:
            "1 año 8 meses asistiendo toda la semana de 7:30 a 4:30 y los últimos cuatro meses ya no asistes a la universidad, realizas una Estadia en una empresa.",
        },
      ],
      preguntasDerecha: [
        {
          pregunta:
            "¿Cuáles son los costos de la Universidad Tecnológica de San Luis Potosí?",
          respuesta01: "Inscripción Anual: ",
          bold01: "$2,000",
          respuesta02: "Cuatrimestre: ",
          bold02: "$3,500",
        },
        {
          pregunta: "¿Qué es la Estadía?",
          respuesta: `Es la realización de un proyecto que los alumnos llevan a cabo en el último cuatrimestre de la carrera como TSU, donde permanecen de tiempo completo dentro de una empresa poniendo en práctica los conocimientos que adquirieron en la Universidad.`,
        },
        {
          pregunta:
            "¿El alumno tiene que buscar la empresa donde realizará la Estadía?",
          respuesta:
            "La Universidad Tecnológica de San Luis Potosí es quien a través de los diferentes convenios con empresas designa los proyectos y las empresas de Estadía a cada uno de sus alumnos.",
        },
        {
          pregunta: "¿Qué es el Sistema Dual?",
          respuesta: `Es un proceso de formación en dos entornos de aprendizaje: Académico y Laboral, recibiendo apoyo por parte de la empresa con instructores, talleres equipados, equipo y herramientas y un apoyo financiero mensual.`,
          parrafo01: "La",
          bold: "Universidad Tecnológica de San Luis Potosí ",
          parrafo02: "  tiene convenio de Sistema Dual con las empresas: ",
          marcas: "BOSCH, BMW, DRAEXLMAIER, DRAXTON",
        },
        {
          pregunta: "¿Hay rutas de transporte directas a la UTSLP?",
          respuesta: `LA UTSLP cuenta con varias rutas que son exclusivas para los alumnos.`,
          link: "http://utslp.edu.mx/index.php/rutas-de-transporte/",
          linkText: "Ver Rutas",
        },
      ],
    };
  },
  methods: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline pt-6";
        case "sm":
          return "display-2 pt-6";
        case "md":
          return "display-1 py-12";
        case "lg":
          return "display-2 py-12";
        case "xl":
          return "display-3 py-12";
      }
    },
    fontSizeText() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "headline";
        case "lg":
          return "title";
        case "xl":
          return "title";
      }
    },
  },
};
</script>
<style scoped>
.bg {
  background-color: #018e25;
}
p {
  margin-bottom: 0;
  color: white;
}
col {
  padding: 0 !important;
}
a {
  color: white;
}
b {
  color: white;
}
</style>
