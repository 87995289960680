<template>
  <v-main>
    <v-container fluid class="py-0">
      <BloqueUno id="Bloque01" />
      <BloqueDos id="Bloque02" />
      <BloqueTres id="Bloque03" />
      <BloqueCuatro id="Bloque04" />
      <BloqueCinco id="Bloque05" />
      <BloqueSeis id="Bloque06" />
      <BloqueSiete id="Bloque07" />
      <WhatsApp />
    </v-container>
  </v-main>
</template>
<script>
import BloqueUno from "./Bloque01.vue";
import BloqueDos from "./Bloque02.vue";
import BloqueTres from "./Bloque03.vue";
import BloqueCuatro from "./Bloque04.vue";
import BloqueCinco from "./Bloque05.vue";
import BloqueSeis from "./Bloque06.vue";
import BloqueSiete from "./Bloque07.vue";
import WhatsApp from "./WhatsApp.vue";
export default {
  /* eslint-disable */
  name: "Index",
  components: {
    BloqueUno,
    BloqueDos,
    BloqueTres,
    BloqueCuatro,
    BloqueCinco,
    BloqueSeis,
    BloqueSiete,
    WhatsApp,
  },
};
</script>
