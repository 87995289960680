<template>
  <v-row align="start" justify="center" class="fill-height bg">
    <v-col md="8">
      <h2 :class="fontSize()" class="text-center font-weight-bold py-6">
        Carreras
      </h2>
      <p class="font-italic" :class="fontSizeText()">
        En la <b>UTSLP</b> ofertamos carreras con
        <b>dos títulos profesionales</b> y más de dos años de experiencia
        profesional.
      </p>
      <p :class="fontSizeText()"><i>Te invitamos a conocerlas.</i></p>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        <v-col
          cols="12"
          md="5"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'pb-0'"
        >
          <Panel
            v-for="item in panelDerecho"
            :key="item.name"
            :tsu="item.tsu"
            :lic="item.lic"
            :name="item.name"
            :color="item.color"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-0'"
        >
          <Panel
            v-for="item in panelIzquierdo"
            :key="item.name"
            :tsu="item.tsu"
            :lic="item.lic"
            :name="item.name"
            :color="item.color"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row justify="center" class="py-12">
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/7mFpka4Jtv4"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Panel from "@/components/cards/expansion-panel.vue";
export default {
  name: "BloqueCuatro",
  components: {
    Panel,
  },
  data: function () {
    return {
      click: true,
      icon: "mdi-plus-circle",
      panelDerecho: [
        {
          name: "Mercadotecnia",
          color: "#684c64",
          tsu: [
            {
              name: "TSU",
              text: "Desarrollo de Negocios Área Mercadotecnia",
              color: "#684c64",
              icon: require("@/assets/images/desk/iconos_UT/mercadotecnia.png"),
            },
          ],
          lic: [
            {
              name: "Licenciatura",
              text: "Lic. en Innovación de Negocios y Mercadotecnia",
              color: "#684c64",
              icon: require("@/assets/images/desk/iconos_UT/mercadotecnia.png"),
            },
          ],
        },
        {
          name: "Logística",
          color: "#3d4061",
          tsu: [
            {
              name: "TSU",
              text: "Logística Área Cadena De Suministros",
              color: "#3d4061",
              icon: require("@/assets/images/desk/iconos_UT/logistic.png"),
            },
          ],
          lic: [
            {
              name: "Licenciatura",
              text: "Lic. en Diseño y Gestión de Redes Logísticas",
              color: "#3d4061",
              icon: require("@/assets/images/desk/iconos_UT/logistic.png"),
            },
          ],
        },
        {
          name: "Mecánica",
          color: "#cf3849",
          tsu: [
            {
              name: "TSU",
              text: "Mecánica Área Industrial",
              color: "#cf3849",
              icon: require("@/assets/images/desk/iconos_UT/mecanica_ind.png"),
            },
          ],
          lic: [
            {
              name: "Ingeniería",
              text: "Ing. en Metal Mecánica",
              color: "#cf3849",
              icon: require("@/assets/images/desk/iconos_UT/mecanica_ind.png"),
            },
          ],
        },
        {
          name: "Mantenimiento",
          color: "#90837a",
          tsu: [
            {
              name: "TSU",
              text: "Mantenimiento Área Industrial",
              color: "#90837a",
              icon: require("@/assets/images/desk/iconos_UT/mantenimiento.png"),
            },
          ],
          lic: [
            {
              name: "Ingeniería",
              text: "Ing. en Mantenimiento Industrial",
              color: "#90837a",
              icon: require("@/assets/images/desk/iconos_UT/mantenimiento.png"),
            },
          ],
        },
        {
          name: "Mecatrónica",
          color: "#e0b652",
          tsu: [
            {
              name: "TSU",
              text: "Mecatrónica Área Automatización",
              color: "#e0b652",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
            {
              name: "TSU",
              text: "Mecatrónica Área Instalaciones Eléctricas Eficientes",
              color: "#e0b652",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
            {
              name: "TSU",
              text: "Mecatrónica Área Sistemas de Manufactura Flexible",
              color: "#e0b652",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
          ],
          lic: [
            {
              name: "Ingeniería",
              text: "Ing. en Tecnologías para la Automatización",
              color: "#e0b652",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
          ],
        },
        {
          name: "Industrial",
          color: "#1172a3",
          tsu: [
            {
              name: "TSU",
              text: "Procesos Industriales Área Manufactura",
              color: "#1172a3",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
            {
              name: "TSU",
              text: "Procesos Industriales Área Plásticos",
              color: "#1172a3",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
            {
              name: "TSU",
              text: "Procesos Área Moldes y Troqueles",
              color: "#1172a3",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
          ],
          lic: [
            {
              name: "Ingeniería",
              text: "Ing. en Sistemas Productivos",
              color: "#1172a3",
              icon: require("@/assets/images/desk/iconos_UT/prcesos_ind.png"),
            },
          ],
        },
      ],
      panelIzquierdo: [
        {
          name: "Tecnologías de la Información",
          color: "#cf3849",
          tsu: [
            {
              name: "TSU",
              text: "Tecnologías de la Información Área Infraestructura de Redes Digitales",
              color: "#cf3849",
              icon: require("@/assets/images/desk/iconos_UT/tecnologias_ind.png"),
            },
          ],
          lic: [],
        },
        {
          name: "Sistemas Automotrices",
          color: "#d04040",
          tsu: [
            {
              name: "TSU",
              text: "Sistemas Automotrices",
              color: "#d04040",
              icon: require("@/assets/images/desk/iconos_UT/sistemas_automotrices.png"),
            },
          ],
          lic: [],
        },
        {
          name: "Administración",
          color: "#f8af5f",
          tsu: [
            {
              name: "TSU",
              text: "Administración Área Capital Humano",
              color: "#f8af5f",
              icon: require("@/assets/images/desk/iconos_UT/administracion.png"),
            },
          ],
          lic: [],
        },
      ],
    };
  },
  methods: {
    changeIcon: function () {
      this.click = !this.click;
      !this.click
        ? (this.icon = "mdi-minus-circle")
        : (this.icon = "mdi-plus-circle");
    },
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-2";
        case "md":
          return "display-1";
        case "lg":
          return "display-2";
        case "xl":
          return "display-2";
      }
    },
    fontSizeText() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "headline";
        case "lg":
          return "title";
        case "xl":
          return "title";
      }
    },
  },
};
</script>
<style scoped>
.bg {
  background-color: #ffffff;
}
</style>
