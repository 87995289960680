<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="bg"
        @click="changeIcon"
        dark
        :color="color"
        >{{ name }}
        <template v-slot:actions>
          <v-icon color="#ffffff">{{ icon }}</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-row v-for="(item, index) in tsu" :key="item.name + index">
              <v-col cols="12">
                <utCard
                  :name="item.name"
                  :text="item.text"
                  :color="item.color"
                  :icon="item.icon"
                ></utCard>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0" cols="12" md="2" v-if="lic.length > 0">
            <v-icon>{{
              $vuetify.breakpoint.mdAndUp ? "mdi-arrow-right" : "mdi-arrow-down"
            }}</v-icon>
            <p>Continuidad a</p>
          </v-col>
          <v-col
            cols="12"
            md="5"
            v-for="(item, index) in lic"
            :key="item.name + index"
          >
            <utCard
              :name="item.name"
              :text="item.text"
              :color="item.color"
              :icon="item.icon"
            ></utCard>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import utCard from "../cards/ut-card";
export default {
  /* eslint-disable */
  name: "Panel",
  components: {
    utCard,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tsu: {
      type: Array,
      required: true,
    },
    lic: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      click: true,
      icon: "mdi-plus-circle",
    };
  },
  methods: {
    changeIcon: function () {
      this.click = !this.click;
      !this.click
        ? (this.icon = "mdi-minus-circle")
        : (this.icon = "mdi-plus-circle");
    },
  },
};
</script>
<style scoped>
.bg {
  color: white;
}
</style>
