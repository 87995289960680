<template>
  <v-row class="bg" align="center" justify="center">
    <v-col cols="10">
      <h2 :class="fontSize()" class="font-weight-bold py-6">Testimoniales</h2>
    </v-col>
    <v-col cols="11">
      <v-carousel hide-delimiter-background cycle>
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" md="3">
              <v-img :src="item.src" contain></v-img>
            </v-col>
            <v-col cols="12" md="7">
              <div class="text-left">
                <b class="font-weight-bold" :class="fontSizeText()">{{
                  item.nombre
                }}</b>
              </div>
              <div class="text-left">
                <b class="font-weight-bold" :class="fontSizeText()">{{
                  item.licenciatura
                }}</b>
              </div>
              <div class="text-left">
                <b class="font-weight-bold" :class="fontSizeText()">{{
                  item.empresa
                }}</b>
              </div>
              <div class="text-left pt-5">
                <p :class="fontSizeText()">
                  {{ item.testimonio }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BloqueCinco",
  data: function () {
    return {
      items: [
        {
          src: require("@/assets/images/desk/logo-01.png"),
          nombre: "Martha Alicia Araiza",
          licenciatura: "Ing. En Mantenimiento Industrial",
          empresa: "Empresa: Contitech",
          testimonio: `La UTSLP cambio mi vida desde que sus programas abarcan más del 90% de los conocimientos que 
          debemos tener para defendernos en el campo laboral y darnos la oportunidad de formar parte de una empresa
          al tener convenios para las estadias`,
        },
        {
          src: require("@/assets/images/desk/logo-02.png"),
          nombre: "Jesús Iván Silva",
          licenciatura: "Ing. Metalmecánica",
          empresa: "Empresa: BAUMUELLER-NÜRMONT",
          testimonio: `Me he logrado distinguir de otros chavos gracias a mi conocimiento tanto práctico
          como teórico. Estudiar en la UTSLP y saber inglés me ha dado la oportunidad de ser seleccionado
          para realizar trabajos en Alemania, Canadá y Estados Unidos.`,
        },
        {
          src: require("@/assets/images/desk/logo-03.png"),
          nombre: "Itzel Reynoso",
          licenciatura: "Ing. Metal Mecánica",
          empresa: "Empresa: FILTER SOLUTIONS",
          testimonio: `El apoyo de la universidad ha sido mucho ya que me formaron en la carrera de Ing. Metal 
          Mecánica con el apoyo del aprendizaje  y la experiencia en el diseño por programas CAD. Actualmente 
          en la empresa puedo aplicar todo el conocimiento adquirido. Cada materia, práctica y aprendizaje han 
          sido importantes en mi formación creando una gran experiencia.`,
        },
        {
          src: require("@/assets/images/desk/logo-04.png"),
          nombre: "Alejandro Eleazar Muñoz ",
          licenciatura:
            "TSU: Mecatrónica área Sistemas de Manufactura Flexible",
          empresa: "Empresa: BOSCH",
          testimonio: `Me ayudo a conseguir un empleo en una empresa reconocida a nivel mundial. 
          Tener conocimientos para generar un eficiente desempeño laboral, resolver los problemas 
          que se presentan día a día y darme la oportunidad de sobresalir.`,
        },
        {
          src: require("@/assets/images/desk/logo-05.png"),
          nombre: "Ivan Cubos",
          licenciatura:
            "TSU: Mecatrónica área Sistemas de Manufactura Flexible",
          empresa: "Empresa: La Costeña",
          testimonio: `Los logros que he obtenido gracias a la UTSLP, con tan solo tener 
          el técnico superior universitario (TSU) ha sido llegar y mantenerme en una empresa 
          reconocida nacional e internacionalmente como es La Costeña con 95 años de trayectoria, 
          que me ha permitido desempeñarme profesionalmente como Mecatrónico en el área de mantenimiento 
          llevando el puesto de encargado, el cual consta de apoyo a mecánicos y a eléctricos y llevar 
          la función de administrador, entre otras.`,
        },
      ],
    };
  },
  methods: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-2";
        case "md":
          return "display-1";
        case "lg":
          return "display-2";
        case "xl":
          return "display-3";
      }
    },
    fontSizeText() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "headline";
        case "lg":
          return "title";
        case "xl":
          return "title";
      }
    },
  },
};
</script>
<style scoped>
.bg {
  background-color: #ededed;
}
</style>
