<template>
  <div class="at-about-fab">
    <a
      href="https://api.whatsapp.com/send?l=es&phone=5214445790633&text=Hola, estoy interesado en"
      target="_blank"
      ><img
        alt="WhatsApp Conviertes"
        src="https://conviertes.com/wp-content/uploads/2019/04/whatsapp.png"
    /></a>
  </div>
</template>
<script>
export default {
  name: "WhatsApp",
};
</script>
<style scoped>
.at-about-fab {
  z-index: 999997;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
}
.at-about-fab img {
  width: 70%;
  height: auto;
}
.at-about-fab a {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .at-about-fab {
    left: 50%;
    bottom: 50px;
  }
  .at-about-fab img {
    width: 80%;
    height: auto;
  }
}
</style>
