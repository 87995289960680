<template>
  <v-container>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div id="load"></div>
      <form
        class="form"
        action="javascript:enviar()"
        onsubmit="return validateForm()"
      >
        <div class="row">
          <div class="col-sm-12">
            <input
              type="text"
              id="nombre"
              name="nombre"
              autocomplete="name"
              placeholder="Nombre"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <input
              name="email"
              id="email"
              type="email"
              autocomplete="email"
              placeholder="Correo Electrónico"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <input
              name="telefono"
              id="telefono"
              type="text"
              autocomplete="tel"
              placeholder="Teléfono"
              pattern="[0-9]{10}"
              title="Solo 10 numeros"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <select name="otro" id="otro" required>
              <option selected disabled>Carrera</option>
              <option value="Mercadotecnia">Mercadotecnia</option>
              <option value="Logística">Logística</option>
              <option value="Mecánica">Mecánica</option>
              <option value="Mantenimiento">Mantenimiento</option>
              <option value="Mecatrónica">Mecatrónica</option>
              <option value="Industrial">Industrial</option>
              <option value="Tecnologías de la Información">
                Tecnologías de la Información
              </option>
              <option value="Sistemas Automotrices">
                Sistemas Automotrices
              </option>
              <option value="Administración">Administración</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <textarea
              rows="7"
              name="mensaje"
              id="mensaje"
              placeholder="Mensaje"
              required
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="text-align: center">
            <button type="submit" id="enviar" class="btn">
              Enviar <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "FormularioContact",
};
</script>
<style scoped></style>
