<template>
  <div id="app">
    <DrawerM />
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import DrawerM from "@/components/core/Drawer.vue";

export default {
  name: "app",
  components: {
    DrawerM,
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
  text-align: left !important;
  padding-left: 2rem;
  color: #ffffff;
}
.v-carousel__controls__item span {
  color: #007034;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h2 {
  color: #5e5e5e;
}
p,
b {
  color: #666666;
}

input.text,
input.title,
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"],
select,
textarea {
  color: #018e25 !important;
  background-color: #ffffff;
  padding: 10px 5px 15px 15px !important;
  border-radius: 7px !important;
  border: 2px solid #018e25 !important;
  font-weight: bold !important;
  line-height: 1em !important;
  text-align: left !important;
  outline: none !important;
  width: 100% !important;
  font-size: 0.8rem !important;
  margin: 0 0 15px 0 !important;
  -webkit-appearance: auto !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #018e25 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #018e25 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #018e25 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #018e25 !important;
}

.btn {
  position: relative;
  padding: 7px 25px !important; /*padding top y botom, izq y der */
  border: 1px solid #018e25;
  -webkit-border-radius: 10px; /*redondeo de boton*/
  -moz-border-radius: 10px; /*redondeo de boton*/
  border-radius: 10px; /*redondeo de boton*/
  background-color: #018e25 !important;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem !important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.btn:hover {
  border-color: #018e25 !important;
  background: #018e25 !important;
  color: #ffffff;
}

.btn:disabled,
.btn[disabled] {
  pointer-events: none;
  background-color: #dfdfdf !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #9f9f9f !important;
  cursor: not-allowed;
}
#__nombre,
#__nombre-error,
#__nivel-error,
#__nivel,
#__telCelular-error,
#__correo,
#__correo-error,
#__telCelular {
  background-color: #ffffff !important;
}

.pros_listaUl {
  list-style: none;
}
.color {
  color: #007034;
}
</style>
