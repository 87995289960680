<template>
  <v-main>
    <v-container fluid class="pa-0" fill-height>
      <v-img :src="image()" style="max-height: 100vh">
        <v-row justify="end">
          <v-col cols="12">
            <v-row>
              <v-col md="8" offset-md="4">
                <v-row justify="center">
                  <v-col
                    cols="4"
                    :offset="$vuetify.breakpoint.smAndDown ? 4 : 0"
                    sm="2"
                  >
                    <v-img
                      src="@/assets/images/desk/logo_banner.png"
                      aspect-ratio="1"
                      contain
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="8" md="12">
                    <p :class="titleSize()" class="font-weight-bold color">
                      ¡Gracias por
                      <br class="hidden-md-and-up" />tu interés!
                    </p>
                    <p :class="fontSize()" class="color">Nos vemos pronto</p>
                    <router-link to="/" tag="button"
                      ><v-btn
                        :large="$vuetify.breakpoint.smAndDown ? true : false"
                        :x-large="$vuetify.breakpoint.lgAndUp ? true : false"
                        color="#E98718"
                        dark
                      >
                        Regresar</v-btn
                      >
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
    </v-container>
  </v-main>
</template>
<script>
export default {
  /* eslint-disable */
  name: "Ty",
  methods: {
    image() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return require("@/assets/images/ty-page/banner-mobile.jpg");
        case "md":
        case "lg":
        case "xl":
          return require("@/assets/images/ty-page/banner.jpg");
      }
    },
    titleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "display-1";
        case "sm":
          return "display-2";
        case "md":
          return "display-2";
        case "lg":
          return "display-3";
        case "xl":
          return "display-4";
      }
    },
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-1";
        case "md":
          return "headline";
        case "lg":
          return "display-1";
        case "xl":
          return "display-2";
      }
    },
    calculaSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "ma-0";
        case "sm":
          return "mt-12 pt-12";
        case "md":
        case "lg":
        case "xl":
          return "";
      }
    },
  },
};
</script>
