<template>
  <v-card class="ma-auto">
    <v-card-title :style="{ backgroundColor: color, color: colorText }">{{
      name
    }}</v-card-title>
    <v-card-text class="text--primary pt-6 bg card">
      <v-avatar><v-img :src="icon" contain /></v-avatar>

      <p class="white--text">{{ text }}</p>
    </v-card-text>
    <v-divider class="white--text"></v-divider>
    <v-card-actions class="bg">
      <v-btn
        text
        icon
        color="#ffffff"
        @click="$vuetify.goTo('#Bloque07', { duration: 1000 })"
      >
        <v-icon>mdi-arrow-down-circle</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "utCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    colorText: {
      type: String,
      default: "#ffffff",
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    cardColor: function () {
      return this.color;
    },
  },
};
</script>
<style scoped>
.bg {
  background-color: #666666;
  color: #ffffff;
}
.card {
  min-height: 130px;
}
</style>
