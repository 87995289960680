<template>
  <v-row>
    <v-col class="pa-0" md="12">
      <v-fab-transition>
        <v-btn
          fab
          color="#00B662"
          fixed
          top
          right
          @click="$store.commit('updateDrawer')"
        >
          <v-icon color="white">
            {{ $store.getters.drawer ? "mdi-close" : "mdi-menu" }}
          </v-icon>
        </v-btn>
      </v-fab-transition>
      <v-img :src="imageHeight()">
        <v-row
          style="min-height: 100vh"
          align="start"
          :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'"
          :class="calculaSpacing()"
        >
        </v-row>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BloqueUno",
  methods: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return require("@/assets/images/mobile/mobile-web.jpg");
        case "md":
        case "lg":
        case "xl":
          return require("@/assets/images/desk/desk-web.jpg");
      }
    },
    titleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "display-1";
        case "sm":
          return "display-2";
        case "md":
          return "display-2";
        case "lg":
          return "display-2";
        case "xl":
          return "display-4";
      }
    },
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "headline";
        case "sm":
          return "display-1";
        case "md":
          return "headline";
        case "lg":
          return "display-1";
        case "xl":
          return "display-2";
      }
    },
    subtitleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return this.titleSize();
        case "md":
          return "font-md";
        case "lg":
          return "font";
        case "xl":
          return "font-lg";
      }
    },
    calculaSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "ma-0";
        case "sm":
          return "mt-12 pt-12";
        case "md":
        case "lg":
        case "xl":
          return "margin";
      }
    },
  },
};
</script>
<style scoped>
.subtitleColor {
  color: #e98718;
}
.font {
  font-size: 4.3rem;
}
.font-md {
  font-size: 4.3rem;
}
.font-lg {
  font-size: 8.3rem;
}
.margin {
  padding-top: 25vh;
}
</style>
